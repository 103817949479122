export default [
    {
        _name: "CSidebarNav",
        _children: [
            {
                _name: "CSidebarNavItem",
                name: "Dashboard",
                to: "/dashboard",
                icon: "cil-speedometer",
                // badge: {
                //   color: 'primary',
                //   text: 'NEW'
                // }
            },
            // Productos
            {
                _name: "CSidebarNavDropdown",
                name: "Productos",
                route: "/productos",
                icon: "cil-blur",
                items: [
                    {
                        name: "Listar",
                        to: "/ecoProductos/list",
                    },
                    {
                        name: "Categorías",
                        to: "/ecoCategorias/list",
                    },
                    {
                        name: "Marcas",
                        to: "/ecoMarcas/list",
                    },
                ],
            },

            // Clientes

            // Informes

            {
                _name: "CSidebarNavTitle",
                _children: ["Otros"],
            },
            // Empresa
            // {
            //     _name: "CSidebarNavDropdown",
            //     name: "Empresa",
            //     route: "/",
            //     icon: "cil-building",
            //     items: [],
            // },
            // Configiguraciones
            {
                _name: "CSidebarNavDropdown",
                name: "Configuraciones",
                route: "/",
                icon: "cil-settings",
                items: [
                    {
                        name: "Parametros de la Aplicación",
                        to: "/configuracionApp/list",
                    },
                    {
                        name: "Etiquetas de Productos",
                        to: "/ecoEtiquetas/list",
                    },
                    {
                        name: "Grupos de Atributos",
                        to: "/ecoAtributosGrupos/list",
                    },
                    {
                        name: "Atributos de Productos",
                        to: "/ecoAtributos/list",
                    },
                    {
                        name: "Banners de Publicidad",
                        to: "/ecoBanners/list",
                    },
                ],
            },

            // Localizaciones
            // {
            //     _name: "CSidebarNavDropdown",
            //     name: "Localizaciones",
            //     route: "/localizaciones",
            //     icon: "cil-globe-alt",
            //     items: [],
            // },
        ],
    },
];